import dayjs from 'dayjs';

export function startOfMonth(t?: dayjs.Dayjs) {
	return (t?.clone() ?? dayjs())
		.startOf('month')
		.startOf('hour')
		.startOf('minute')
		.startOf('second');
}

export function endOfMonth(t?: dayjs.Dayjs) {
	return (t?.clone() ?? dayjs())
		.endOf('month')
		.endOf('hour')
		.endOf('minute')
		.endOf('second');
}

export function yearBefore4After10(t: dayjs.Dayjs) {
	const year = t.year();
	const yearBefore4 = year - 4;
	const yearBefore10 = year + 10;

	const list = [];
	for (let i = yearBefore4; i < yearBefore10; i++) {
		list.push(i);
	}

	return list;
}

export function calMonthGenerate() {
	const start = 0;
	const end = 11;

	const list = [];
	for (let i = start; i < end; i++) {
		list.push(i);
	}

	return list;
}

export const timeFormatMode = 'MMM D, YYYY';
export function timestampToString(timestamp: string | Date) {
	return dayjs(timestamp).format(timeFormatMode);
}
